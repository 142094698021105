import { library } from "@fortawesome/fontawesome-svg-core";
import {
  faBootstrap,
  faCss3Alt,
  faGit,
  faGithub,
  faGitlab,
  faHtml5,
  faJs,
  faNodeJs,
  faReact,
  faWordpressSimple,
  faDocker,
  faLinux,
} from "@fortawesome/free-brands-svg-icons";

import {
  faEnvelope,
  faMobileAlt,
  faPhone,
  faCode,
  faDatabase,
  faServer,
  faLayerGroup,
} from "@fortawesome/free-solid-svg-icons";

// Add the imported icons to the Font Awesome library
library.add(
  faReact,
  faJs,
  faHtml5,
  faCss3Alt,
  faBootstrap,
  faGit,
  faGithub,
  faGitlab,
  faNodeJs,
  faWordpressSimple,
  faDocker,
  faDatabase,
  faLinux,
  faCode,
  faServer,
  faLayerGroup,
  faMobileAlt,
  faEnvelope,
  faPhone
);

// Enhanced Home Page Data
const HomePageData = {
  twitterHandle: "@vallarasuk143",
  siteUrl: "Vallarasu K",
  siteBrowserUrl: "https://vallarasuk.com/",
  siteResourceBrowserUrl: "https://vallarasuk.com/resources/",
  resourceSiteUrl: "Developer Resources",
  iconImage: require("../Assets/vallarasuk.jpeg"),
  titleIcon: require("../Assets/logo/favicon-16x16.png"),
  cardImage: require("../Assets/vallarasuk.jpeg"),
  mobileProfileImage: require("../Assets/vallarasuk.jpeg"),
  loadingAnimation: require("../Assets/loading-gifs/profile-loading.gif"),
  mainScreenLoading: require("../Assets/loading-gifs/Jelly-Preloader.webp"),
  resumeDownloadLink: require("../Assets/resume/vallarasuk.pdf"),
  resumeDownloadLinkUpdate: require("../Assets/resume/VallarasuResume.pdf"),
  whatsAppChannelUrl:"https://chat.whatsapp.com/JzCFT47gI6aE8O6mJA96V0",
  resumeFileName: "Vallarasu K",
  name: "Vallarasu Kanthasamy",
  role: " Software Developer",
  roles: [
    "React Developer",
    "WordPress Developer",
    "Full-Stack Developer",
    "React Native Developer",
  ],
  bio: "I am Vallarasu K, a highly skilled software developer with over 2 years of experience in React, WordPress custom plugin development, and full-stack development using Node.js and Express.js. I specialize in building dynamic, high-performance web applications with a strong focus on user experience and responsiveness.",
  email: "contact@vallarasuk.com",
  emailTo: "mailto:contact@vallarasuk.com",
  mobile: "8122992143",
  mobileTo: "tel:8122992143",
  instagramProfileLink: "https://www.instagram.com/vallarasu_kanthasamy/?hl=en",
  twitterProfileLink: "https://twitter.com/vallarasuk143",
  linkedInProfileLink: "https://www.linkedin.com/in/vallarasu-k/",
  githubProfileLink: "https://github.com/vallarasuk",
  blogWebsite: "https://blog.vallarasuk.com",
  title: "Explore My Web Universe",
  description: [
    "Hey there! I'm Vallarasu K, a passionate React Developer and Full-Stack Engineer with expertise in building scalable, high-performance web applications.",
    "With over 3 years of hands-on experience, I specialize in React, Next.js, Node.js, Express.js, and WordPress custom development. My skills extend to Magento 2, MySQL, and REST API integrations, allowing me to create dynamic, feature-rich platforms tailored to business needs.",
    "I have worked on custom themes, plugins, and UI/UX improvements for enterprise-level applications, e-commerce solutions, and SaaS platforms. My projects include responsive web design, auto-content posting systems, ATS Resume Maker, and advanced category-based user management.",
    "Beyond development, I am passionate about performance optimization, SEO-friendly architectures, and building tools that enhance user experience and business growth.",
    "Explore my work, connect with me, and let's build something extraordinary together!",
  ],
  pageTitle: "Vallarasu Kanthasamy | React Developer",
  resourcePageTitle: "Developer Resources | Vallarasu Kanthasamy",
  projectButtonName: "View My Projects",
  projectButtonlink: "/projects",
  skillsTitle: "My Skills and Expertise",
  skills: [
    { name: "React", icon: faReact },
    { name: "JavaScript", icon: faJs },
    { name: "TypeScript", icon: faJs },
    { name: "HTML", icon: faHtml5 },
    { name: "CSS", icon: faCss3Alt },
    { name: "SCSS", icon: faCss3Alt },
    { name: "Bootstrap", icon: faBootstrap },
    { name: "Tailwind CSS", icon: faCss3Alt },
    { name: "Git", icon: faGit },
    { name: "GitHub", icon: faGit },
    { name: "GitLab", icon: faGit },
    { name: "Node.js", icon: faNodeJs },
    { name: "Express.js", icon: faNodeJs },
    { name: "Next.js", icon: faReact },
    { name: "WordPress", icon: faWordpressSimple },
    { name: "PHP", icon: faWordpressSimple },
    { name: "MySQL", icon: faDatabase },
    { name: "MariaDB", icon: faDatabase },
    { name: "MongoDB", icon: faDatabase },
    { name: "REST API", icon: faCode },
    { name: "GraphQL", icon: faCode },
    { name: "React Native", icon: faReact },
    { name: "Redux", icon: faReact },
    // { name: "Zustand", icon: faReact },
    { name: "Three.js", icon: faReact },
    { name: "Framer Motion", icon: faReact },
    // { name: "Docker", icon: faDocker },
    // { name: "Jest", icon: faJs },
    { name: "Testing Library", icon: faJs },
    { name: "Webpack", icon: faJs },
    { name: "Vite", icon: faJs },
    { name: "Firebase", icon: faDatabase },
    // { name: "Linux", icon: faLinux },
    // { name: "Bash Scripting", icon: faLinux },
    { name: "Responsive Web Design", icon: faMobileAlt },
  ],

  projectsData: [
    {
      title: "850+ PDF Resources",
      description: "Useful IT Resources and 850+ PDF files.",
      livePreview: "https://vallarasuk.com/resources",
      previewImage: require("../Assets/resources.png"),
    },
    {
      title: "ATS Resume Maker",
      description: "Fill All The Details & Get the Resume.",
      livePreview: "https://atsresumemaker.vallarasuk.com/",
      previewImage: require("../Assets/ATSResume-Maker.png"),
    },

    {
      title: "Food Recipe Website",
      description:
        " Developed a food recipe website using React, showcasing modern UI practices.",
      livePreview: "https://vallarasuk-frontend.netlify.app/",
      previewImage: require("../Assets/swiggy-clone.png"),
    },
    {
      title: "Setup and Training",
      description:
        "Developed and deployed a modern training setup using cutting-edge web technologies.",
      livePreview:
        "https://www.fondriest.com/field-station/set-up-and-training/",
      previewImage: require("../Assets/image.png"),
    },
    {
      title: "Home Page",
      description:
        "Designed a responsive and interactive homepage prioritizing user experience.",
      livePreview: "https://www.fondriest.com/field-station/",
      previewImage: require("../Assets/fieldstation-Homepage.png"),
    },
    {
      title: "Education Outreach",
      description:
        "Created an educational outreach platform to foster community engagement.",
      livePreview:
        "https://www.fondriest.com/field-station/educational-outreach/",
      previewImage: require("../Assets/education-outreach.png"),
    },
    {
      title: "Otto Clone",
      description:
        "Cloned and customized the Otto landing page using React, showcasing modern UI practices.",
      gitLink: "https://github.com/vallarasuk/otto-landing-page",
      livePreview: "https://vallarasuk.github.io/otto-landing-page/",
      previewImage: require("../Assets/otto landing.png"),
    },
    {
      title: "Macro Touch Clone",
      description:
        "Replicated the Macro Touch interface with a strong emphasis on responsive design.",
      gitLink: "https://github.com/vallarasuk/Macro-Touch",
      livePreview: "https://vallarasuk.github.io/Macro-Touch/",
      previewImage: require("../Assets/macroTouch.png"),
    },
    {
      title: "About Internet Blog",
      description:
        "Developed a blog platform that shares insights on front-end development and best practices.",
      gitLink: "https://github.com/vallarasuk/Front-End-Blog",
      livePreview: "https://vallarasuk.github.io/Front-End-Blog/",
      previewImage: require("../Assets/about internet blog.png"),
    },
    {
      title: "Portfolio",
      description:
        "Created a personal portfolio to showcase my skills, projects, and experiences.",
      livePreview: "https://vallarasuk.w3spaces.com/",
      previewImage: require("../Assets/portfolio 2.png"),
    },
    {
      title: "Nexsens CB 250",
      description:
        "Developed a product page for the Nexsens CB 250 data buoy, enhancing user interaction.",
      livePreview: "https://www.nexsens.com/products/data-buoys/cb-250",
      previewImage: require("../Assets/cb 250 page.png"),
    },
    {
      title: "Nexsens X2 Environmental",
      description:
        "Built a comprehensive product page for the Nexsens X2 environmental data logger.",
      livePreview:
        "https://www.nexsens.com/products/data-loggers/x2-environmental-data-logger",
      previewImage: require("../Assets/Nexsens X2 page.png"),
    },
    {
      title: "Nexsens Contact Us",
      description:
        "Designed a user-friendly contact page to improve customer communication.",
      livePreview: "https://www.nexsens.com/support/contact-us",
      previewImage: require("../Assets/Nexsens Contact us page.png"),
    },
    {
      title: "Nexsens YSI",
      description:
        "Developed a product page for the YSI EXO3 multi-parameter water quality sonde, enhancing product visibility.",
      livePreview:
        "https://www.nexsens.com/products/sensors/water-quality/ysi-exo3-multi-parameter-water-quality-sonde",
      previewImage: require("../Assets/YSI page.png"),
    },
  ],
  studies: [
    {
      year: "2017 - 2020",
      title: "Diploma in Mechanical Engineering",
      institution: "Government Polytechnic College",
      description:
        "Acquired a foundational understanding of engineering principles, which sparked my interest in technology.",
      icon: "graduation",
    },
    {
      year: "2016 - 2017",
      title: "Secondary School Leaving Certificate (SSLC)",
      institution: "National Matriculation School",
      description:
        "Focused on computer science and mathematics, enhancing my analytical skills.",
      icon: "school",
    },
  ],
  aboutTitle: "Hello, I'm Vallarasu K",
  aboutDescription:
    "I'm a passionate software developer specializing in React, Next.js, WordPress, and full-stack development with Node.js and Express.js. My journey started with a Diploma in Mechanical Engineering, where I discovered my love for coding. With over 3 years of experience, I currently work as a React Developer at Thidiff Technology in Bangalore, building scalable web applications, custom WordPress solutions, and Magento 2 extensions. I thrive on crafting seamless user experiences, optimizing performance, and exploring emerging technologies to push the boundaries of web development.",
  keywords: [
    "ATS Resume Maker",
    "atsresumemaker.vallarasuk.com",
    "React developer",
    "JavaScript developer",
    "WordPress plugin developer",
    "Node.js developer",
    "Express.js developer",
    "React Native developer",
    "front-end development",
    "full-stack development",
    "web development",
    "JavaScript web development services",
    "React.js",
    "Node.js",
    "Express.js",
    "React Native",
    "WordPress",
    "Bootstrap",
    "Git",
    "CSS3",
    "HTML5",
    "freelance React developer",
    "hire React developer",
    "Bangalore React developer",
    "JavaScript expert India",
    "WordPress developer Bangalore",
    "Node.js full-stack developer",
    "React Native mobile app developer",
    "custom WordPress plugin development",
    "responsive web design",
    "interactive UI design",
    "landing page development",
    "dynamic web application",
    "mobile-responsive web design",
    "educational outreach platform",
    "environmental data logger page",
    "product page development",
    "software development services",
    "web application development",
    "mobile app development",
    "custom WordPress solutions",
    "front-end web design",
    "digital experience design",
    "Bangalore software developer",
    "Bangalore web developer",
    "React developer Bangalore",
    "WordPress developer India",
    "web development Bangalore",
    "hire React developer India",
    "React Native mobile app services Bangalore",
    "Bangalore WordPress developer",
    "JavaScript development Bangalore",
    "web development company Bangalore",
  ],

  resourceKeywords: [
    "ATS Resume Maker",
    "atsresumemaker.vallarasuk.com",
    "React developer",
    "JavaScript developer",
    "WordPress plugin developer",
    "Node.js developer",
    "Express.js developer",
    "React Native developer",
    "front-end development",
    "full-stack development",
    "web development",
    "JavaScript web development services",
    "React.js",
    "Node.js",
    "Express.js",
    "React Native",
    "WordPress",
    "Bootstrap",
    "Git",
    "CSS3",
    "HTML5",
    "freelance React developer",
    "hire React developer",
    "Bangalore React developer",
    "JavaScript expert India",
    "WordPress developer Bangalore",
    "Node.js full-stack developer",
    "React Native mobile app developer",
    "custom WordPress plugin development",
    "responsive web design",
    "interactive UI design",
    "landing page development",
    "dynamic web application",
    "mobile-responsive web design",
    "educational outreach platform",
    "environmental data logger page",
    "product page development",
    "software development services",
    "web application development",
    "mobile app development",
    "custom WordPress solutions",
    "front-end web design",
    "digital experience design",
    "Bangalore software developer",
    "Bangalore web developer",
    "React developer Bangalore",
    "WordPress developer India",
    "web development Bangalore",
    "hire React developer India",
    "React Native mobile app services Bangalore",
    "Bangalore WordPress developer",
    "JavaScript development Bangalore",
    "web development company Bangalore",
    "0.Top50InterviewQuestions",
    "1.TopAmazonQuestions",
    "2.TopFacebookQuestions",
    "3.TopGoogleQuestions",
    "4.TopLinkedinQuestions",
    "70 Toughest interivew questions ",
    "HR Interview Questions",
    "Interview Preparation Books/Cracking the Coding Interview - 189 Programming Questions and Solutions (6th Edition)",
    "Interview Preparation Books/How to Answer the 64 Toughest Interview Questions",
    "Interview Preparation Books/cracking the tech career",
    "Interview_preparation_4_with_anno",
    "Top 57 Interview Questions Answers",
    "450 DSA Question Sheet by Love Babbar",
    "75 Most Important 𝐋𝐞𝐞𝐭𝐂𝐨𝐝𝐞 𝐃𝐒𝐀 𝐐𝐮𝐞𝐬𝐭𝐢𝐨𝐧𝐬",
    "Amazon SDE Sheet",
    "DSA Sheet by Arsh (45 Days Plan)",
    "DSA by Shradha Didi & Aman Bhaiya",
    "Google LeetCode Questions",
    "Leetcode DSA sheet by Fraz ",
    "Striver SDE Sheet",
    "CODING BLOCK DSA & CP NOTES/Coding Block 1",
    "CODING BLOCK DSA & CP NOTES/Coding Block 2",
    "CODING BLOCK DSA & CP NOTES/Mastering Competitive Programming @csalgo Telegram",
    "C++ Complete Notes",
    "CompetitiveProgrammersHandbook",
    "Cormen, Thomas H._ Leiserson, Charles E._ Rivest, Ronald L._ Ste - Introduction to Algorithms (2022) - libgen.li",
    "DSA Notes",
    "DSA Supreme",
    "Arrays_and_Strings_Quickstart_Guide",
    "Data_Structures_and_Algorithms_Study_Guide",
    "Graphs_Quickstart_Guide",
    "Linked_List_Quickstart_Guide",
    "Recursion_Quickstart_Guide",
    "Trees_Quickstart_Guide",
    "Module_1_Slides",
    "Module_1_Workbook",
    "Module_2_Slides",
    "Module_2_Workbook",
    "Module 3/Module_3_Slides",
    "Module 3/Module_3_Workbook",
    "Module 4/Module_4_Slides",
    "Module 4/Module_4_Workbook",
    "Module 5/Module_5_Slides",
    "Module 5/Module_5_Workbook",
    "Module 6/Module_6_Slides",
    "Module 6/Module_6_Workbook",
    "08.. Number-Theory/Number Theory",
    "09.. Bit-Manupulation/Bit Manipulation Notes By Kapil Yadav",
    "1. Intro+Recursion",
    "10 Golden Rules for Coding Interviews ",
    "BinarySearchTrees",
    " Pointer-&-References/Pointers",
    " Pointer-&-References/References in C++",
    "100 Aptitude Trick",
    "100 DSA questions",
    "100 Most Asked ANGULAR  QnA ",
    "100 java programs",
    "Greedy+Backtracking",
    " Sorting-Algorithm/SORTING ALGORITHMS",
    "110 Most Challenging Interview Questions.pdf",
    "12. DynamicProgramming",
    "12.. Recursion & Backtracking/1. Recursion AND Backtracking",
    "120 Core Java Interview Q & A With Solutions",
    "13. Graphs",
    "13.. Linked-List/4. Linked List",
    "14.. Stacks-&-Queues/7. Stacks & Queues",
    "15 things I wish I was told in my 20s",
    "8. Dynamic Programming-1",
    "9. Dynamic Programming-2",
    "Dynamic Memory Allocation",
    "Dynamic Programming",
    "16.. Greedy-Approach/GREEDY APPROACH",
    "164 Data Science Interview Questions",
    "2. Trees -1",
    "3. Trees - 2",
    "Binary Indexed Tree",
    "Segment Trees",
    "5. Graph - 1",
    "6. Graph - 2",
    "GRAPH (Part-2) by Kapil Yadav",
    "GRAPH NOTES BY KAPIL YADAV-1",
    "Graph Algorithms",
    "OOPS in C++",
    "2. Array+Searching",
    "20 Coding Patterns",
    "20 DSA patterns ",
    "20 Important Coding Patterns ",
    "200 HR Interview Questions",
    "200+ FREE RESOURCES ON WEB DEVELOPEMENT ",
    "239 Excel Keyboard shortcuts",
    "240_Core_Java_Interview_Questions_&_Solutions",
    "280_DS Complete",
    "3. Sorting",
    "30 Chrome Extension 💡",
    "30 Days Of React Ebook Fullstack",
    "30 Most asked in Amazon",
    "300 Companies Compensation for Freshers in India 2023",
    "4. Hashing",
    "400+ Javascript Interview Questions ",
    "400+ javascript questions ",
    "5. Strings",
    "50 Awesome Chat-GPT Prompts ",
    "50 Most Commonly asked Interview Questions",
    "50+Day+Challenge+",
    "500 Manual testing interview questions-answer set. ",
    "500 Resources for Web Developers",
    "500+ Data Engineering interview questions",
    "6. LinkedList",
    "60 Commonly Interview Questions and Answers ",
    "60 Toughest Interview Questions 📗",
    "7 Chat Gpt Plugins I Use For Fb Ads Optimization",
    "7. Stacks",
    "8 powerful skills ",
    "8. Queues",
    "9. Trees",
    "ADVANCED JAVASCRIPT CONCEPTS CHEATSHEET ",
    "AI Terms",
    "API Cheat Sheet",
    "API Terms",
    "API reference",
    "API_Cheat_sheet_1700973735",
    "ATS CV Templates",
    "AWS_CertOverivew",
    "AZ 104",
    "Advanced JavaScript Concepts",
    "Alogrithm and Complexity",
    "[Thomas_H._Cormen,_Charles_E._Leiserson,_Ronald_L.(BookFi.org)",
    "big-o-cheatsheet",
    "GUI Design for Android apps",
    "JavaAndroidProgramming",
    "The Busy Coder's Guide to Advanced Android Development",
    "android-application-development-for-for-dummies",
    "professional_android_4_application_development-compressed",
    "Android User Interface Design ..",
    "Android-Getting-Started",
    "Android-UI-Design",
    "Android.Application.Development.for.For.Dummies",
    "JavaAndroidProgramming",
    "The Busy Coders Guide To Android Development",
    "android_tutorial",
    "Angular JS notes",
    "angularjs-in-19-days1",
    "angularjs_tutorial",
    "Ansible Configuration Management, 2nd Edition",
    "Ansible Playbook Essentials",
    "Ansible- Up and Running",
    "Learning Ansible - Madhurranjan Mohaan",
    "Api Quick Reference",
    "Artificial Intelligence Basics A Non Technical Introduction",
    "Artificial Intelligence – A Modern Approach (3rd Edition)",
    "ai-machine-learning",
    "artificial_intelligence_with_python_tutorial",
    "BEGINNER'S JAVASCRIPT CHEATSHEET ",
    "Backend Development Roadmap",
    "Backend Roadmap",
    "Beginning ReactJS Foundations",
    "Best JMeter Guide ",
    "Best Python Cheatsheet Ever",
    "Best resume building sites",
    "Big Data Notes",
    "Big O Cheatsheet",
    "Blockchain notes___",
    "Bootstrap-Programming-Cookbook",
    "Bosscoder ",
    "Build your API with Spring",
    "C INTERVIEW SERIES",
    "C Language Notes",
    "C++ Interview Guide",
    "C & C++ Programming Style Guidlines",
    "C And C++ Complete Programming Book(2)",
    "C++ Language Tutorial",
    "C++ Programming for the Absolute Beginner, Second Edition",
    "C++.Primer.Plus.6th.Edition.Oct.2011",
    "Cpp_reference",
    "Games-in-SFML Game Development",
    "Open Data Structures (in C++)",
    "SAMS Teach Yourself C++ in One Hour a Day",
    "Starting Out With C++ From Control Structures Through Objects 8th Edition",
    "Thinking_In_C++_2nd",
    "Wiley Publishing, Inc - C++ for Dummies, 5th Edition",
    "ANSI-C dennis ritchie kerninghan",
    "C How to Program by Dietel",
    "C The Complete Reference 4th Ed Herbert Schildt[www.fullfreedownload.in]",
    "C-Programming-Absolute-Beginner-ssp.indian@gmail.com_",
    "Data Structures Through c ",
    "Expert_C_Programming",
    "Turbo_C_Users_Guide_1987",
    "c-programming-in-linux",
    "cprogramming_tutorial",
    "CICD",
    "CLOUD COMPUTING DIGITAL NOTES (R18A0523)",
    "CMD Commands",
    "COMPLETE JAVASCRIPT NOTES ",
    "COMPUTER GRAPHICS NOTES",
    "CP",
    "CPP Notes for Professionals",
    "CSS CHEATSHEET",
    "CSS-Notes-by-CodingWithYash",
    "CYBERSECURITY BASICS",
    "Calculus For Data Science ",
    "Chef Essentials",
    "Chef Infrastructure Automation Cookbook, Second Edition",
    "Architecting the Cloud - Design Decisions for Cloud Computing Service Models",
    "Cloud Computing Concepts  Technology & Architecture",
    "Cloud Computing Design Patterns",
    "Cloudonomics-The_Economics_of_Cloud_Computing",
    "infrastructure-as-code",
    "Coding For Beginners In Easy Steps Basic Programming",
    "Coding Interview - Must do questions",
    "Common Backend Interview Questions",
    "Common Interview Mistakes ",
    "CompVisNotes",
    "5. Algorithms For Compiler Design kakade",
    "ALSUdragonbook",
    "Compilers-Principles-Techniques-and-Tools-Book-Preview",
    "compilerDesignInC",
    "Complete js interview preparation",
    "Completed Interview Guide .  -copied-",
    "Core Java Interview Questions and Answers ",
    "Core Topics and Reference",
    "Core_Java_Interview_Questions",
    "Cracking  Java Interviews ",
    "Cracking Spring  Microservices  Interviews",
    "Css Interview questions",
    "Curious Freaks Coding Sheet - Google Sheets",
    "Cv.pdf",
    "Hacking Computer Hacking Security Testing Penetration Testing and Basic Security",
    "Hacking- The Art of Exploitation (2nd ed. 2008) - Erickson",
    "Metasploit-The-Penetration-Tester-s-Guide",
    "Penetration Testing - A hands-on introduction to Hacking",
    "Practical_Malware_Analysis",
    "The Hacker Playbook 3_ Practical Guide To Penetration Testing - PDF Room",
    "The_Art_of_Human_Hacking",
    "applied-cryptography-2nd-ed-b-schneier",
    "python",
    "DBMS Full Notes ",
    "DBMS-Interview-Questions/DBMS Interview Questions",
    "DBMS",
    "DP on grids",
    "DSA 100 Days Roadmap ",
    "DSA 30 Days",
    "Data Structure Interview Questions",
    "Top 50 Array Coding Problems for Interviews",
    "Top 50 DSA programming interview questions",
    "top-50-data-structure-interview-questions",
    "Data Analytics   Visualization All-in-One For Dummies",
    "Data Cleaning",
    "Data Fetching in Next.js",
    "Data Science interview questions",
    "Data Science from Scratch_ First Principles with Python - PDF Room",
    "Head First Statistics - PDF Books",
    "Practical Statistics for Data Scientists",
    "Python-for-Data-Analysis",
    "pythondatasciencehandbook",
    "Advanced Data Structures [Brass 2008-09-08]",
    "Data Strcucture  And Algorithm",
    "Data Structures and Algorithms in Java Fourth Edition",
    "GrokkingAlgorithms_SampleChapter2",
    "Introduction_to_algorithms-3rd Edition",
    "Data Structures And Algorithms",
    "DataStructure Notes",
    "Deploying Rails with Docker, Kubernetes and ECS",
    "Design Patterns Interview Questions",
    "DevOps Essentials concepts",
    "DevOps Interview Questions & Answers ",
    "DevOps Interview Questions and Answers 📘",
    "DevOps Package for freshers",
    "Devops",
    "Docker Q & A",
    "Docker Tutorial ",
    "Build Your Own PaaS with Docker",
    "Docker Cookbook",
    "Docker- Up and Running",
    "Introduction to Docker",
    "Kubernetes Microservices with Docker",
    "Learning Docker",
    "Orchestrating Docker",
    "Pro Docker",
    "The Docker Book",
    "Using Docker",
    "What-is-Docker",
    "docker-in-action",
    "docker_tutorial",
    "DotNETFrameworkNotesForProfessionals",
    "Dynamic programming notes (basic)",
    "ES6 for Humans",
    "Effective JavaScript",
    "Elixir in Action",
    "Encapsulation Binary program(type 1).docx",
    "Encapsulation Binary program(type 2).docx",
    "Erlang and Elixir for Imperative Programmers",
    "Excel_Advanced_Training_Packet",
    "Extending Jenkins",
    "FRONT END FUNDAMENTALS",
    "FULLSTACK MERN PREMIUM BATCH",
    "Finance Interview Prep",
    "Flask/Flask_Web_Development_201404",
    "Flutter Tutorial",
    "Front-end Roadmap",
    "Frontend Developer Interview Questions ",
    "Frontend Fundamentals",
    "Frontend Interview Question",
    "Frontend Interview Questions",
    "Frontend development roadmap",
    "FullStack React Book",
    "GIT CHEAT SHEET",
    "GIT Notes",
    "Getting Started with Kubernetes",
    "Git CheatSheet",
    "Git Study Materials",
    "Git-1",
    "Git Pocket Guide",
    "Mastering Git",
    "Pro Git, 2nd Edition",
    "Talk_Git",
    "Version Control with Git, 2nd Edition",
    "git.from.bottom.up",
    "peepcode-git",
    "GitHub commands ",
    "0321774639",
    "GoBootcamp",
    "Introducing Go",
    "Learning-Go-latest",
    "Network_Programming_in_GoLang",
    "The Go Programming Language",
    "build-web-application-with-golang-en",
    "Graphic Design Portfolio ",
    "Graphs Data Structures",
    "HR Interview Questions",
    "HR-Interview-Questions-and-Answers",
    "HR-Interview-Questions/HR Interview Questions",
    "Handout_5_PF",
    "The Web Book-A4-HM",
    "little-book-html-css-coding-guidelines",
    "HTML-Notes-by-CodingWithYash",
    "HTML5 notes",
    "HandWritten Notes(AZ-900)",
    "Harvard’s guide for resume writing",
    "Hibernate Overview Notes",
    "How To Code In Reactjs",
    "Html Auestions",
    "Html Deep Question",
    "Html, CSS, Bootstrap, Javascript & jQuery Notes",
    "IBM  INTERVIEW  EXPERIENCE",
    "Importance Of Domain Knowledge ",
    "Important Javascript Array Methods ",
    "Infosys_Very_Important",
    "Interview Preparation Guide",
    "Interview Preparation Road Map ",
    "Interview QnAs- CloudyML",
    "Interview Question and Answers Part `1(1)",
    "50-Coding-Interview-Questions",
    "Competitive Programmer_s Handbook",
    "Cracking the coding interview 6th edition",
    "FAANG",
    "SQL Tutorial",
    "SQL",
    "Scientific Programming",
    "Software Engineering at Google",
    "The Self-Taught Programmer",
    "leetcode_interview_questions",
    "Interview and Resume Guide ",
    "Interview questions OOPS , DBMS , OS , CN & HR ",
    "301 smart answers to tough interview questions - Vicky Oliver",
    "Amy Cuddy - Presence, Bringing Your Boldest Self ( PDFDrive.com )",
    "How-to-Talk-to-Anyone-Book-PDF-by-Leil-Lowndes",
    "Interview-guide-web",
    "What Color is Your Parachute",
    "Introducing Elixir, 2nd Edition",
    "Introducing Elixir",
    "Introduction of C++ STL",
    "Introduction to Algorithms",
    "IoT-Lecture_Notes_EIE",
    "JAVA Coding Interview Questions With Answer",
    "JIRA For Beginners ",
    "JQuery/jQuery For Dummies",
    "JQuery/jQuery_Cookbook",
    "JS Interview Questions",
    "JS Notes",
    "JS interview QA (2)",
    "Java Interview  Q&A",
    "Java Interview Questions Interview 👨‍💻",
    "Java Interview Questions and Answers ",
    "Java Interview Questions",
    "Java Programs With Solution",
    "Java Programs",
    "Java most important interview Questions",
    "Java-Interview-Questions",
    "Java-Interview-Questions/Core Java -Basics of Java Interview Questions",
    "Java-Interview-Questions/Core Java OOPs- Polymorphism Interview Questions",
    "Complete Reference",
    "Core Java volume-I fundamentals",
    "Effective Java",
    "Introduction to Java Programming, Comprehensive Version, 10th Edition- Y. Daniel Liang",
    "Introduction to Programming Using Java",
    "Java - How To Program, 4th Edition-1",
    "Java how to program 9th edition",
    "Java,Java, Java-OO Problem Solving",
    "JavaStructures",
    "Java_Design_Pattern_eBook",
    "ThinkJava",
    "JavaNotesForProfessionals",
    "JavaScript Handwritten Notes🔥",
    "JavaScript Interview Preparation Materials",
    "JavaScript Interview Question",
    "JavaScript Notes ",
    "JavaScript Notes for Beginners",
    "JavaScript Notes for Professionals",
    "JavaScript The Definitive Guide, 6th Edition",
    "JavaScript The Good Parts",
    "JavaScript notes",
    "JavaScript tips",
    "ASmarterWaytoLearnJavaScript",
    "angularjs_tutorial",
    "javascript_tutorial",
    "Eloquent_JavaScript",
    "JavaScriptNotesForProfessionals",
    "Object Oriented JavaScript 2nd Edition",
    "[O`Reilly] - JavaScript. The Definitive Guide, 6th ed. - [Flanagan]",
    "javascript_the_good_parts",
    "Javascript ",
    "Javascript Code Snippets ",
    "Javascript Handwritten Notes ",
    "Javascript topperworld Notes",
    "Jenkins From Scratch",
    "Jenkins Q&A",
    "Jenkins",
    "Jira Notes",
    "Kafka with spring boot",
    "Know All API Terms in 7 Minutes",
    "KotlinNotesForProfessionals",
    "Kubernetes Tutorial ",
    "Kubernetes interview Questions ",
    "Kubernetes",
    "Kubernetes_guide",
    "25 Laravel Tips and Tricks - Envato Tuts+ Code Tutorial",
    "easylaravel-sample",
    "l4-offline-doc",
    "laravel-4_2-docs",
    "laravel_application_development_blueprints",
    "laravel_from_apprentice_to_artisan",
    "laravel_starter",
    "laravel_tutorial",
    "LearingSpringBoot2_2ndEdition",
    "Learning Chef",
    "Learning Puppet 4",
    "Leetcode Probledm Blind 75",
    "Leetcode SQL problems with solutions",
    "Linked List sample program 1.docx",
    "Linux Command Line And Shell Scripting Bible Wiley_2008",
    "The Linux Programmers Toolbox",
    "The.Linux.Command.Line",
    "advanced-linux-programming",
    "bash_cheat_sheet",
    "beginning-linux-programming",
    "c-and-unix-programming",
    "linux_programming_unleash",
    "linuxbible2002",
    "unixbook",
    "LinuxNotesForProfessionals",
    "MAANG Interview Coding Patterns",
    "MERN Full Stack",
    "MERN stack roadmap",
    "ML Cheatsheet 🔥🔥😎",
    "ML Interview Questions and Answers",
    "ML Projects in Python",
    "800 Data Science Questions",
    "AI CheatSheet",
    "Approaching almost any Machine Learning problem",
    "Collective Intelligence",
    "Data Engineering Cookbook",
    "Deep Learning with Python",
    "ESLII",
    "Hand-on-M",
    "Hands on Machine Learning with Scikit Learn and Tensorflow",
    "Interview Questions",
    "Machine Learning Projects Python",
    "Machine Learning Yearning",
    "Machine Learning for Hackers_ Case Studies and Algorithms to Get You Started",
    "Mathematics for Machine learning",
    "Natural Language Processing with Python",
    "PracticalStatistics",
    "Probability",
    "Python Data Science Handbook",
    "Python-for-Data-Analysis",
    "ThinkStats",
    "ML",
    "MOngoDB Complete NOTES ♦️",
    "Machine - Learning - Tom Mitchell-compressed",
    "Machine Learning for Hackers_ Case Studies and Algorithms to Get You Started [Conway & White 2012-02-25]",
    "Pattern Recognition And Machine Learning - Springer  2006",
    "TheHundred-pageMachineLearning",
    "understanding-machine-learning-theory-algorithms",
    "Master Recursion in DSA in 10 days ",
    "Mastering Chef Provisioning",
    "Mastering Chef",
    "Mastering Puppet, Second Edition",
    "MongoDB HandBook",
    "MongoDBNotesForProfessionals",
    "Most Frequently asked Interview Questions",
    "Networking Interview Questions",
    "Networking-Interview-Questions/Networking Interview Questions",
    "Neural Networks/nature_of_code-005_shiffman_12.4.12",
    "Mastering Nginx",
    "Nginx Essentials",
    "Nginx HTTP Server, 2nd Edition",
    "Nginx High Performance",
    "nodejs_tutorial",
    "NodeJSNotesForProfessionals",
    "Nodejs Best Practices",
    "NotesOnMachineLearningForBTech-1",
    "A Passage to India",
    "Anna_Karenina_NT",
    "Don Quixote",
    "Jane Eyre",
    "ONE HUNDRED YEARS OF SOLITUDE-MARQUEZ",
    "Ralph-Ellison-Invisible-Man-Text",
    "Things fall apart",
    "To Kill a Mockingbird",
    "Toni Morrison_Beloved",
    "Virginia Woolf - Mrs. Dalloway",
    "Why Not Me A feeling of millions (English version) by Agrawal, Anubhav [Agrawal, Anubhav] (z-lib.org).epub",
    "denis-johnson---train-dreams",
    "the-great-gatsby",
    "OOPS Concepts",
    "OOPS IN C++ HANDWRITTEN",
    "OOPS-Interview-Questions/OOPs Interview Questions",
    "OOPS",
    "OOPs important interview question",
    "OS-Interview-Questions/Operating System Interview Question",
    "OS_Full_Notes",
    "Object Oriented Programming in C++",
    "Object-Oriented Analysis and Design for Information Systems",
    "Ocean of PDF",
    "Oops Notes 💡",
    "Operating System Interview Question (1)",
    "Operating System Interview Question",
    "OperatingSystem",
    "Excel Formulas",
    "MCQ in CS",
    "Microsoft Excel for Beginners",
    "Pro GIT",
    "Problem Solving Tools",
    "The web application hackers handbook",
    "excel-1",
    "PHP-Cheat-Sheet",
    "05-welling-php-mysql-web",
    "nodejs_tutorial",
    "PHP Objects, Patterns, and Practice",
    "PHP Solutions - Dynamic Web Design Made Easy (2006)",
    "PHP and MySQL Web Development 4th Ed-tqw-_darksiderg",
    "Sams.Advanced.PHP.Programming.673pp.3-2004",
    "php_master",
    "PHPNotesForProfessionals",
    "POSTMAN_GUIDE_For_API_Testing__1676529401",
    "Possible Arrays and Matrix Problems.docx",
    "Possible Linked List Problems",
    "Possible MultiThreading Interview Questions.docx",
    "Possible SDLC Interview Questions.docx",
    "Possible strings Problems.docx",
    "PostgreSQLNotesForProfessionals",
    "Pro Apache Phoenix",
    "Pro Continuous Delivery",
    "ProGit_2ndEdition",
    "Programming JavaScript Applications",
    "(Ebook - English) Mcgraw-Hil  Pic Robotics -- A Beginner S Guide To Robotic",
    "Arduino Introduction to programming",
    "ESPWithThePIC16F877",
    "McGraw.Hill.PIC.Microcontroller.Project.Book.May.2000.ISBN.0",
    "PIC Microcontrollers - 50 Projects for Beginners and Experts",
    "Programming 32-bit Microcontrollers in C - Exploring the PIC32 - Lucio Di  ",
    "Programming 8-bit PIC Microcontrollers in C 2008 Martin PBates",
    "arduino_notebook_v1-1",
    "book_adv_pic_in_c",
    "eBook - PIC Programming with C",
    "pic-microcontrollers",
    "Puppet 4 Essentials",
    "Puppet for Containerization",
    "Python Complete Notes",
    "Python For Data Science Cheatsheet",
    "Python Interview Questions",
    "Automate the boring stuff with python",
    "Hacking Secret ciphers with python",
    "Invent with python",
    "Learn Python 3 the Hard Way",
    "Python Tricks the book",
    "Python cookbook",
    "Think Python",
    "Python Programming for Beginners (2024)",
    "Python Questions",
    "Python Tools",
    "Python-Interview-Questions/Python Interview Questions",
    "Learning Python 5th Ed 2013",
    "Learning with Python How to Think Like a Computer Scientist",
    "Python Crash Course",
    "Python for Everybody",
    "byteofpython_120",
    "python-cheat-sheet-v1",
    "python_basics",
    "python_tutorial-good",
    "the_ultimate_beginners_guide",
    "think_python",
    "think_python_2e",
    "PythonNotesForProfessionals",
    "REACT DESIGN PATTERNS AND BEST PRACTICES",
    "React 18 Design Patterns and Best Practices",
    "React Beginners Handbook",
    "React interview questions - Scrimba",
    "React inteview questions",
    "React resources for beginner to advanced ",
    "ReactJSNotesForProfessionals(2)",
    "ReactJSNotesForProfessionals",
    "Getting Started with React Native",
    "Mastering React",
    "Pro React",
    "React- Up &  Running",
    "ReactNativeNotesForProfessionals",
    "Redux Questions",
    "Road to learn React JS ",
    "Roadmap For Machine Learning 📌",
    "RubyNotesForProfessionals",
    "RubyOnRailsNotesForProfessionals",
    "SAP MM Notes",
    "SAP MM Questionnaire ",
    "SORTING ALGORITHM",
    "SQL Interview Questions",
    "SQL Interview Questions and Answers",
    "TOP 50 SQl queries for interview",
    "Skills Of Successful Software Engineer",
    "Software Development Fundamentals",
    "Software Development",
    "Solutions of Leetcode Problems _)",
    "Splunk Document",
    "Spring Interview Questions Answers ",
    "SpringFrameworkNotesForProfessionals",
    "Step-by-Step Guide to Implement ML Algos with Python",
    "Striver Recursion Notes ",
    "Striver SDE Sheet ",
    "Symfony_book_2.0",
    "symfony2-for-beginners",
    "System Design Handbook - Aman Barnwal",
    "System Design Interview Prep Notes - Revanth Murigipudi",
    "System Desing Interview Prep",
    "System Design",
    "Systems Design RoadMap",
    "TCS SQL Interview Questions ",
    "TCS SQL.INTERVIEW QUESTION AND ANSWER ",
    "THE ROAD TO LEARN REACT ",
    "TOP 50 REACT JS INTERVIEW QUESTIONS AND ANSWERS",
    "TOP 6 ALGORITHMS",
    "Technical Interview Question Reference Links",
    "Terraform Notes For Freshers",
    "Time & Space Complexity",
    "Atomic Habits by James Clear",
    "Eat that frog by brian tracy",
    "Ikigai",
    "Rework",
    "Rich Dad Poor Dad",
    "The 5 AM Club - Robin Sharma",
    "The 7 Habits Of Highly Effective People",
    "The Compound Effect - Darren hardy",
    "Top 10 Machine Learning Algorithms ",
    "Top 100 Product Based Companies in India",
    "Top 175 Selenium Questions ",
    "Top 250+ Interview Questions on AWS",
    "Top 50 Python Interview Questions with Answers ",
    "Top GIT Interview Questions ",
    "Top OOPs Questions",
    "Top React Interview Questions And Answers",
    "Top React Interview Questions",
    "Touch Interview Questions",
    "Toughest Interview Questions",
    "Toughest_Behavioral_Based_Interview_Questions_and_Answers",
    "Tricky interview questions 👉Swipe",
    "UI Technologies 💡",
    "UI Technologies",
    "UNIX Tutorials",
    "Ultimate CSS cheat sheet",
    "Unix & Linux microcheat book",
    "Unlocking the Code",
    "VS Code Cheat Sheet",
    "Creating Development Environments with Vagrant",
    "Pro Vagrant",
    "Vagrant_Up_and_Running",
    "The principle of beautiful web design",
    "Web Developer Roadmap ",
    "Don't Make Me Think, Steve Krug, Revisited, 3rd Edition",
    "Eloquent_JavaScript",
    "HTML & CSS by Jon Duckett",
    "JavaScript-and-JQuery-Interactive-Front-End-Web-Development-Introduction",
    "Learning-web-4ed_Robbins",
    "Web Standards",
    "Wipro Elite Eng MCA Syllabus and Pattern",
    "The wordpress anthology",
    "WordPress 3 Plugin Development Essentials",
    "WordPress 3.0 jQuery",
    "WordPress Theme Development, 3rd Edition",
    "WordPress for Web Developers",
    "Yii/yii2-guide",
    "Agile Estimating and Planning",
    "Art of Agile Development",
    "CI/jenkins-the-definitive-guide",
    "Continuous Delivery",
    "android_tutorial",
    "angular-2-test-driven-development-2nd",
    "become-ninja-angular2",
    "angular_notes_complete",
    "angularjs_tutorial",
    "apis/Designing Evolvable Web APIs with ASP.NET",
    "c++ notes",
    "complete js Notes ",
    "Java Concurrency in Practice",
    "Pro Asynchronous Programming with .NET",
    "concurrent_programming_on_windows",
    "cpp_tutorial",
    "devops-2-1-toolkit-deploying-monitoring",
    "kubernetes/Kubernetes-Microservices with Docker",
    "kubernetes/getting-started-kubernetes-2nd",
    "kubernetes/mastering-kubernetes",
    "pro-continuous-delivery-jenkins-2",
    "api-driven-devops",
    "devops-2-0-toolkit",
    "docker-bootcamp",
    "docker-cookbook-solutions-examples",
    "docker-in-practice",
    "docker-orchestration",
    "native-docker-clustering-swarm",
    "pro-docker",
    "the-docker-book",
    "using-docker",
    "dsa",
    "elasticsearch/elasticsearch",
    "Beginning Amazon Web Services with Node.js",
    "Learning Single-page Web Application Development",
    "Mastering Web Application Development with Express",
    "Pro Express.js",
    "Pro REST API Development with Node.js",
    "RESTful Web API Design with Node.js",
    "Web Development with Node and Express",
    "express-in-action",
    "flutter_tutorial",
    "git ",
    "proGit",
    "gitcheatsheet",
    "Go Recipes",
    "Introducing-Go",
    "The.Go.Programming.Language",
    "antitextbookGo",
    "go-building-web-applications",
    "go-design-patterns",
    "go-in-action",
    "go-in-practice",
    "go-programming-blueprints-2nd",
    "go-web-programming",
    "go",
    "introduction_to_tableau_training_0",
    "Data Structures and Algorithms",
    "EF JS sonsivri",
    "Third-Party JavaScript",
    "expert-javascript",
    "javascript_the_good_parts",
    "javascript-interview-questions-and-answers-pdf-free (1)",
    "javascript-interview-questions-and-answers-pdf-free",
    "js",
    "learn PowerBi pdf",
    "Linux Bible",
    "Linux Pocket",
    "introduction-machine-learning-python",
    "microservices-for-java-developers",
    "advanced-microservices",
    "building-microservices-designing-fine-grained-systems",
    "developing-microservices-node-js",
    "devops-web-development",
    "mesos-in-action",
    "microservices-building-scalable-software",
    "microservices-deployment-cookbook",
    "microservices-docker-microsoft-azure",
    "microservices-flexible-software-architecture",
    "microservices-from-day-one",
    "mongodb",
    "node. js",
    "node.js interview questions",
    "Node- Up and Running",
    "Node.js By Example",
    "Node.js Design Patterns",
    "Node.js Recipes",
    "Node.js the Right Way",
    "Practical Node.js",
    "Pro Node.js for Developers",
    "Professional Node.js",
    "Using Node.js for UI Testing",
    "php_cookbook",
    "php_tutorial",
    "react/Pro React",
    "reactjs",
    "records",
    "recursion ",
    "HAL",
    "OReilly.REST.API.Design.Rulebook.Oct.20SBN.1449310508",
    "RESTful Java Patterns and Best Practices",
    "RESTful Java Web Services Security",
    "RESTful Java with JAX-RS 2.0, 2nd Edition",
    "RESTful Web APIs",
    "RESTful Web Services with Dropwizard",
    "RESTful_Web_Services",
    "build-apis-you-wont-hate",
    "rest-advanced-research-topics-and-practical-applications",
    "service_design_patterns",
    "Beautiful Code",
    "Clean Code",
    "Code Complete",
    "Refactoring  Improving the Design of Existing Code",
    "The Pragmatic Programmer",
    "clean-coder-conduct-professional-programmers",
    "domain-driven-design-distilled",
    "sql_cheat_sheet_for_data_science",
    "ELS2015",
    "Eric Ries - The Lean Startup",
    "FRIED_Jason_-_Rework",
    "Hackers and Painters",
    "Lean Customer Development",
    "Lean Enterprise",
    "Lean UX",
    "The Startup Owner s Manual_ The Step-by-Step Guide for Building a Great Company - Blank, Steve",
    "UX for Leaan Startups",
    "entrepreneur revolution",
    "running-lean-iterate-from-plan-a-to-a-plan-that-works-lean-series",
    "steve_jobs_walter_isaacson",
    "the-lean-mindset-ask-the-right-questions",
    "university-startups-and-spin-offs-guide-for-entrepreneurs-in-academia",
    "why-startups-fail-and-how-yours-can-succeed",
    "zero-one",
    "statistics",
    "BDD",
    "Design Driven Testing",
    "Test Driven",
    "Test-Driven JavaScript Development 2",
    "Test-Driven JavaScript Development",
    "bdd-in-action",
    "developer-testing",
    "quality-code-software-testing-principles-practices-and-patterns",
    "scala-test-driven-development",
    "tdd-ebook-sample",
    "the-art-of-unit-testing",
    "the-basics-of-ux-design",
    "top 75 node js questions",
    "top excel shortcuts",
    "toughest interview questions ",
    "vbscript",
    "pro-vim-2014",
    "tmux-taster-2014",
    "𝐉𝐚𝐯𝐚 𝐈𝐧𝐭𝐞𝐫𝐯𝐢𝐞𝐰 𝟔𝟗 𝐐𝐮𝐞𝐬𝐭𝐢𝐨𝐧𝐬.pdf",
    "𝗖𝗼𝗺𝗽𝘂𝘁𝗲𝗿_𝗡𝗲𝘁𝘄𝗼𝗿𝗸_𝗜𝗻𝘁𝗲𝗿𝘃𝗶𝗲𝘄_𝗤𝘂𝗲𝘀𝘁𝗶𝗼𝗻𝘀_📋🎙️",
    "𝙉𝙤𝙩𝙚𝙨 𝙤𝙛 𝘿𝙟𝙖𝙣𝙜𝙤",
    "👉TOP 50 OOPs Interview Questions⏩ ",
    "🔺GOOGLE🔺 INTERVIEW GUIDE ",
    "🔺OOP C++🔺 INTERVIEW SERIES",
  ],
};

export default HomePageData;
