import React, { useEffect, useState } from "react";
import { Button, Modal } from "react-bootstrap";


const Resources = () => {
  const [pdfFiles, setPdfFiles] = useState([]);
  const [selectedPdf, setSelectedPdf] = useState(null);
  const [showModal, setShowModal] = useState(false);
  const [requestModal, setRequestModal] = useState(false); // State for request modal
  const [searchQuery, setSearchQuery] = useState("");
  const [loading, setLoading] = useState(true);
  const [requestTitle, setRequestTitle] = useState(""); // New state for the title input
  const [requestDescription, setRequestDescription] = useState(""); // State for description
  const [userName, setUserName] = useState(""); // State for user name
  const [userMobile, setUserMobile] = useState(""); // State for user mobile number

  useEffect(() => {
    setTimeout(() => {
      const importAll = (r) => {
        return r.keys().map((file) => ({
          file: r(file),
          name: file.replace("./", "").replace(".pdf", ""),
        }));
      };

      const pdfs = importAll(
        require.context("./Assets/resources", true, /\.pdf$/)
      );
      pdfs.sort((a, b) => a.name.localeCompare(b.name));
      setPdfFiles(pdfs);
      setLoading(false);
    }, 2000);
  }, []);

  const handleClose = () => {
    setShowModal(false);
    setSelectedPdf(null);
  };

  const handleRequestClose = () => {
    setRequestModal(false);
    setRequestTitle(""); // Clear the form fields
    setRequestDescription("");
    setUserName(""); // Clear user name
    setUserMobile(""); // Clear user mobile
  };

  const handleRequestSubmit = () => {
    // Create a WhatsApp message URL with the request details
    const message = `Hello, my name is ${userName} and my mobile number is ${userMobile}.\nI would like to request a PDF titled: "${requestTitle}".\nDescription: ${requestDescription}`;
    const phoneNumber = "918122992143"; // Including +91 in the number
    const whatsappLink = `https://wa.me/${phoneNumber}?text=${encodeURIComponent(
      message
    )}`;

    // Redirect to WhatsApp
    window.open(whatsappLink, "_blank");

    handleRequestClose();
  };

  const filteredPdfs = pdfFiles.filter((pdf) =>
    pdf.name.toLowerCase().includes(searchQuery.toLowerCase())
  );

  return (
    <div className="container my-3 my-md-0 p-0">
      {/* Search Input */}
      <div className="search-bar-container">
        <div className="d-flex justify-content-between align-items-center">
          <input
            type="text"
            className="form-control search-bar"
            placeholder="Search PDFs..."
            value={searchQuery}
            onChange={(e) => setSearchQuery(e.target.value)}
            aria-label="Search PDF Resources"
          />
          <Button
            variant="info"
            onClick={() => setRequestModal(true)}
            className="ms-3 btn-sm btn-sm-responsive"
          >
            Request a PDF
          </Button>
        </div>
        <span className="ms-2 mt-2 d-block">
          Available PDF Resources ({filteredPdfs.length})
        </span>
      </div>

      {/* PDF Cards */}
      <div className="row row-cols-2 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 g-3">
        {loading ? (
          [...Array(8)].map((_, index) => (
            <div className="col" key={index}>
              <div className="card h-100 shadow-lg border-0 rounded-4 overflow-hidden">
                <div className="card-body p-2 skeleton-loader">
                  <div
                    className="bg-light mb-2"
                    style={{ height: "100px" }}
                  ></div>
                  <div
                    className="bg-light mb-2"
                    style={{ height: "20px", width: "70%" }}
                  ></div>
                  <div
                    className="bg-light"
                    style={{ height: "30px", width: "100%" }}
                  ></div>
                </div>
              </div>
            </div>
          ))
        ) : filteredPdfs.length > 0 ? (
          filteredPdfs.map((pdf, index) => (
            <div className="col" key={index}>
              <div className="card h-100 shadow-sm border-0 rounded-4 overflow-hidden pdf-card">
                <div
                  className="card-img-top bg-danger text-white d-flex align-items-center justify-content-center"
                  style={{
                    height: "100px",
                    background:
                      "linear-gradient(to bottom right, #ff6347, #8b0000)",
                  }}
                >
                  <i
                    className="fas fa-file-pdf"
                    style={{ fontSize: "56px", color: "white" }}
                  ></i>
                </div>

                <div className="card-body d-flex flex-column justify-content-between p-2">
                  <h5
                    className="card-title text-dark bg-white mb-2 fw-bold text-center"
                    style={{
                      fontSize: "clamp(14px, 2.5vw, 16px)",
                    }}
                  >
                    {pdf.name}
                  </h5>

                  <div className="d-flex justify-content-center mt-auto">
                    <a
                      href={pdf.file}
                      download={`${pdf.name}.pdf`}
                      className="btn btn-outline-light btn-sm download-btn"
                      style={{
                        backgroundColor: "#007bff",
                        color: "white",
                        borderRadius: "8px",
                        padding: "8px 20px",
                        border: "none",
                        fontWeight: "500",
                      }}
                    >
                      <i className="fas fa-download me-2"></i> Download
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))
        ) : (
          <p className="text-center">
            No PDF files found matching your search.
          </p>
        )}
      </div>

      {/* Modal to view PDF */}
      <Modal show={showModal} onHide={handleClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>{selectedPdf?.name}</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          {selectedPdf && (
            <iframe
              src={selectedPdf.file}
              title={selectedPdf.name}
              style={{ width: "100%", height: "500px" }}
            ></iframe>
          )}
        </Modal.Body>
        <Modal.Footer>
          <a
            href={selectedPdf?.file}
            download={`${selectedPdf?.name}.pdf`}
            className="btn btn-outline-success"
          >
            <i className="fas fa-download"></i> Download PDF
          </a>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      {/* Modal for requesting a PDF */}
      <Modal show={requestModal} onHide={handleRequestClose} size="lg" centered>
        <Modal.Header closeButton>
          <Modal.Title>Request a New PDF</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <form>
            <div className="mb-3">
              <label htmlFor="userName" className="form-label">
                Your Name
              </label>
              <input
                type="text"
                className="form-control"
                id="userName"
                value={userName}
                onChange={(e) => setUserName(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="userMobile" className="form-label">
                Your Mobile Number
              </label>
              <input
                type="tel"
                className="form-control"
                id="userMobile"
                value={userMobile}
                onChange={(e) => setUserMobile(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="requestTitle" className="form-label">
                PDF Title
              </label>
              <input
                type="text"
                className="form-control"
                id="requestTitle"
                value={requestTitle}
                onChange={(e) => setRequestTitle(e.target.value)}
                required
              />
            </div>
            <div className="mb-3">
              <label htmlFor="requestDescription" className="form-label">
                PDF Description
              </label>
              <textarea
                className="form-control"
                id="requestDescription"
                value={requestDescription}
                onChange={(e) => setRequestDescription(e.target.value)}
                rows="3"
                required
              ></textarea>
            </div>
          </form>
        </Modal.Body>
        <Modal.Footer>
          <Button variant="primary" onClick={handleRequestSubmit}>
            Submit Request
          </Button>
          <Button variant="secondary" onClick={handleRequestClose}>
            Close
          </Button>
        </Modal.Footer>
      </Modal>

      <style jsx>{`
        .pdf-card:hover {
          transform: translateY(-5px);
          box-shadow: 0 4px 20px rgba(0, 0, 0, 0.2);
        }

        .download-btn:hover {
          background-color: #0056b3;
        }

        .skeleton-loader div {
          animation: pulse 1.5s ease-in-out infinite;
        }

        @keyframes pulse {
          0% {
            background-color: #f0f0f0;
          }
          50% {
            background-color: #e0e0e0;
          }
          100% {
            background-color: #f0f0f0;
          }
        }

        /* Fix the search bar at the top */
        .search-bar-container {
          position: sticky;
          top: 0;
          z-index: 1000;
          background-color: white;
          padding: 15px 0;
          border-bottom: 1px solid #ddd;
        }

        .search-bar {
          max-width: 500px;
          margin: 0 auto;
        }

        /* Make the button smaller on mobile */
        .btn-sm-responsive {
          font-size: 12px;
          padding: 6px 12px;
        }

        /* For medium and larger screens, make the button size bigger */
        @media (min-width: 768px) {
          .btn-sm-responsive {
            font-size: 14px;
            padding: 8px 16px;
          }
        }

        /* For extra-large screens, further increase button size */
        @media (min-width: 1200px) {
          .btn-sm-responsive {
            font-size: 16px;
            padding: 10px 20px;
          }
        }
      `}</style>
    </div>
  );
};

export default Resources;
