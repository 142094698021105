import React, { useEffect } from "react";
import { Helmet } from "react-helmet-async";

const SeoMetaTags = ({
  name,
  role,
  titleIcon,
  keywords,
  cardImage,
  twitterHandle,
  siteUrl,
  siteBrowserUrl,
  pageTitle,
  pageUrl,
}) => {
  const description = `Explore the portfolio of ${name}, a full-stack developer skilled in React.js, WordPress, and web development. Check out my free resources for developers at ${siteUrl}/resources.`;

  // Ensure URLs are always fully qualified
  const absolutePageUrl =
    pageUrl && pageUrl.startsWith("http")
      ? pageUrl
      : `${siteBrowserUrl}${pageUrl || ""}`;
  const dynamicTitle = pageTitle
    ? `${pageTitle} | ${name}`
    : `${name} | Software Developer`;

  return (
    <Helmet>
      <title>{dynamicTitle}</title>
      <meta name="title" content={dynamicTitle}></meta>
      <meta name="description" content={description} />
      <meta name="keywords" content={keywords && keywords.join(", ")} />
      <meta name="author" content={`${name} - ${role}`} />
      <meta name="robots" content="index, follow"></meta>

      <meta httpEquiv="Content-Type" content="text/html; charset=utf-8"></meta>
      <meta name="language" content="English"></meta>

      {/* Open Graph Meta Tags */}
      <meta property="og:title" content={dynamicTitle} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={cardImage} />
      <meta property="og:url" content={absolutePageUrl} />
      <meta property="og:type" content="website" />
      <meta property="og:locale" content="en_US" />
      <meta property="og:site_name" content="Vallarasu Kanthasamy Portfolio" />
      <meta property="og:updated_time" content={new Date().toISOString()} />
      <meta property="og:image:alt" content={`${name} | Software Developer`} />

      {/* Twitter Meta Tags */}
      <meta name="twitter:card" content="summary_large_image" />
      <meta name="twitter:creator" content={twitterHandle} />
      <meta name="twitter:title" content={dynamicTitle} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={cardImage} />
      <meta name="twitter:image:alt" content={`${name} | Software Developer`} />
      <meta name="twitter:site" content={twitterHandle} />

      <link rel="icon" href={cardImage} type="image/png" />

      {/* JSON-LD Schema Markup */}
      <script type="application/ld+json">
        {JSON.stringify({
          "@context": "https://schema.org",
          "@type": "Person",
          name: name,
          url: absolutePageUrl,
          sameAs: ["https://www.linkedin.com/in/vallarasu-k/"],
          description: description,
          mainEntityOfPage: absolutePageUrl,
          breadcrumb: {
            "@type": "BreadcrumbList",
            itemListElement: [
              {
                "@type": "ListItem",
                position: 1,
                name: "Home",
                item: siteBrowserUrl,
              },
              {
                "@type": "ListItem",
                position: 2,
                name: "Resources",
                item: `${siteBrowserUrl}/resources`,
              },
            ],
          },
          publisher: {
            "@type": "Organization",
            name: "Vallarasu Kanthasamy",
            url: siteBrowserUrl,
            logo: {
              "@type": "ImageObject",
              url: cardImage,
            },
          },
        })}
      </script>
    </Helmet>
  );
};

export default SeoMetaTags;
