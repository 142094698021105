import { createClient } from "@supabase/supabase-js";

// Function to return the Supabase client
export const getSupabaseClient = () => {
  const supabaseUrl = "https://esobjkdsqwmmzhcwvzck.supabase.co"; // Your Supabase URL
  const supabaseKey = "eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJpc3MiOiJzdXBhYmFzZSIsInJlZiI6ImVzb2Jqa2RzcXdtbXpoY3d2emNrIiwicm9sZSI6InNlcnZpY2Vfcm9sZSIsImlhdCI6MTc0MzYyMzg1MCwiZXhwIjoyMDU5MTk5ODUwfQ.WINGdCQot67KsRHcKnIM4OaIErO78noTFjSdCTkD91M"; // Your anon key

  // Initialize Supabase client with anon key
  const supabase = createClient(supabaseUrl, supabaseKey);

  return supabase;
};
