import { faFileDownload, faBook } from "@fortawesome/free-solid-svg-icons"; // Import resource-related icon
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import { Button } from "react-bootstrap"; // Importing Button from react-bootstrap
import { useNavigate } from "react-router-dom"; // Import useNavigate to handle navigation

const ResourcesAndResume = ({ resumeDownloadLinkUpdate, resumeFileName }) => {

  console.log(resumeDownloadLinkUpdate, resumeFileName);
  const navigate = useNavigate(); // Initialize useNavigate for navigating to resources

  // Resume download logic
  const handleResumeDownload = () => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split("T")[0]; // Format: YYYY-MM-DD

    // Create an anchor element
    const link = document.createElement("a");
    link.href = resumeDownloadLinkUpdate;
    link.target = "_blank"; // Open in a new tab/window
    link.download = `${resumeFileName}_${formattedDate}.pdf`;

    // Append the anchor element to the DOM and trigger download
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link); // Clean up
  };

  // Navigation to /resources
  const handleResourcesClick = () => {
    navigate("/resources");
  };

  return (
    <div className="row mt-2">
      <div className="col-12 d-flex justify-content-center gap-3 mt-4">
        {/* Resume Button */}
        <button
          onClick={handleResumeDownload}
          className="btn px-lg-4 py-2 shadow rounded-pill pointer text-center hover-animation"
          style={{ backgroundColor: "#007bff", color: "#fff" }} // Custom background and text color for Resume button
        >
          <FontAwesomeIcon size="lg" icon={faFileDownload} />
          <span className="ms-2 fw-bold">Resume</span>
        </button>

        {/* Resources Button */}
        <Button
          onClick={handleResourcesClick}
          className="btn px-lg-4 py-2 shadow rounded-pill pointer text-center hover-animation"
          style={{ backgroundColor: "#28a745", color: "#fff" }} // Custom background and text color for Resources button
        >
          <FontAwesomeIcon size="lg" icon={faBook} /> {/* Icon for Resources */}
          <span className="ms-2 fw-bold">Resources</span>
        </Button>
      </div>
    </div>
  );
};

export default ResourcesAndResume;
