import React, { useState } from "react";
import { Modal, Button } from "react-bootstrap";
import { useSpring, animated } from "react-spring";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faWhatsapp } from "@fortawesome/free-brands-svg-icons";
import "./WhatsAppModal.css"; // Import the CSS file for animations

const WhatsAppModal = ({ show, handleClose, whatsAppChannelUrl }) => {
  const [isAnimating, setIsAnimating] = useState(false);
  const [isVisible, setIsVisible] = useState(show);

  const animation = useSpring({
    opacity: isAnimating ? 0 : 1,
    transform: isAnimating ? `scale(1.1)` : `scale(1)`,
    config: { tension: 300, friction: 20 },
    onRest: () => {
      if (isAnimating) {
        setIsVisible(false);
        handleClose();
      }
    },
  });

  const handleCancel = () => {
    setIsAnimating(true);
  };

  React.useEffect(() => {
    if (show) {
      setIsVisible(true);
      setIsAnimating(false);
    } else {
      setIsAnimating(false);
      setIsVisible(false);
    }
  }, [show]);

  return (
    <Modal
      show={isVisible}
      onHide={handleClose}
      centered
      backdrop="static"
      aria-labelledby="contained-modal-title-vcenter"
      size="sm" // Set modal size to small
      style={{ borderRadius: "15px" }}
    >
      <animated.div
        style={{
          ...animation,
          padding: "20px",
          borderRadius: "15px",
          background: "linear-gradient(135deg, #f0f4c3, #c8e6c9)",
          boxShadow: "0 10px 30px rgba(0, 0, 0, 0.2)",
        }}
        className={isAnimating ? "broken-glass" : ""}
      >
        <Modal.Header closeButton onHide={handleClose}>
          <div style={{ display: "flex", alignItems: "center" }}>
            <FontAwesomeIcon
              icon={faWhatsapp}
              size="2x"
              style={{ color: "#25D366", marginRight: "10px" }}
            />
            <Modal.Title style={{ fontWeight: "bold", fontSize: "1.2rem" }}>
              Connect on WhatsApp
            </Modal.Title>
          </div>
        </Modal.Header>
        <Modal.Body>
          <p className="m-0" style={{ fontSize: "0.9rem" }}>
            Join our WhatsApp community using the link below:
          </p>
        </Modal.Body>
        <Modal.Footer className="p-0" style={{ border: "none" }}>
          <div className="d-flex justify-content-between w-100">
            <Button
              variant="secondary"
              onClick={handleCancel}
              style={{
                borderRadius: "8px",
                background: "rgba(0, 0, 0, 0.1)",
                color: "#555",
                border: "none",
              }}
            >
              Cancel
            </Button>
            <Button
              variant="primary"
              href={whatsAppChannelUrl}
              target="_blank"
              onClick={handleClose}
              style={{
                borderRadius: "8px",
                background: "#25D366",
                color: "#fff",
                border: "none",
              }}
            >
              Join Now
            </Button>
          </div>
        </Modal.Footer>
      </animated.div>
    </Modal>
  );
};

export default WhatsAppModal;
