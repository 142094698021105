import React, { useState, useEffect, useCallback } from "react";
import { getSupabaseClient } from "./Lib/getSupabaseClient"; // Assuming Supabase client is configured

const Quotes = () => {
  const [images, setImages] = useState([]); // To store images
  const [loading, setLoading] = useState(false); // To track loading state
  const [error, setError] = useState(null); // For error handling
  const [page, setPage] = useState(1); // To track current page number
  const [hasMore, setHasMore] = useState(true); // To track if more images are available

  const database = getSupabaseClient(); // Get the Supabase client

  // Function to fetch images from the database
  const fetchImages = useCallback(async () => {
    if (loading || !hasMore) return; // If already loading or no more images
    setLoading(true); // Set loading to true when fetching

    try {
      const { data, error } = await database
        .from("quotes_images")
        .select("*")
        .range((page - 1) * 50, page * 50 - 1); // Fetch images in pages of 50

      if (error) {
        throw error;
      }

      setImages((prevImages) => [...prevImages, ...data]); // Add new images to the existing ones
      setPage((prevPage) => prevPage + 1); // Increment page number for next fetch
      if (data.length < 50) {
        setHasMore(false); // If less than 50 images were fetched, no more images are available
      }
    } catch (err) {
      setError("Error fetching images. Please try again.");
    } finally {
      setLoading(false); // Set loading to false after the fetch completes
    }
  }, [loading, hasMore, page, database]);

  // Use the IntersectionObserver to detect when user reaches 85% of the page height
  const observer = useCallback(
    (node) => {
      if (loading) return; // Don't observe if we're already loading
      const options = {
        root: null,
        rootMargin: "0px",
        threshold: 0.85, // Trigger when 85% of the target is visible
      };

      const handleIntersection = (entries, observer) => {
        entries.forEach((entry) => {
          if (entry.isIntersecting) {
            fetchImages(); // Trigger image fetch when threshold is reached
          }
        });
      };

      const intersectionObserver = new IntersectionObserver(
        handleIntersection,
        options
      );
      if (node) {
        intersectionObserver.observe(node);
      }

      return () => {
        if (node) {
          intersectionObserver.unobserve(node);
        }
      };
    },
    [loading, fetchImages]
  );

  useEffect(() => {
    fetchImages(); // Initial fetch of images
  }, [fetchImages]);

  return (
    <div className="container my-5">
      {error && <p style={{ color: "red" }}>{error}</p>}

      {/* Grid layout with Tailwind CSS for a Pinterest-like effect */}
      <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-3 gap-4">
        {images.map((img, index) => (
          <div
            key={index}
            className="card bg-white shadow-lg rounded-lg overflow-hidden"
          >
            <div className="card-body">
              {/* Apply center focus crop without stretching */}
              <div className="relative w-full h-96 overflow-hidden">
                <img
                  src={img.url}
                  alt={img.name}
                  className="w-full h-full object-cover object-center"
                />
              </div>

              {/* Button icons for View and Download */}
              <div className="flex justify-between mt-4">
                {/* View Button with Icon */}
                <button
                  className="bg-blue-500 text-white p-2 "
                  onClick={() => window.open(img.url, "_blank")}
                >
                  <i className="fa fa-eye"></i> {/* Font Awesome "view" icon */}
                </button>

                {/* Download Button with Icon */}
                <button
                  className="bg-green-500 text-white p-2 rounded-6"
                  onClick={() => {
                    const a = document.createElement("a");
                    a.href = img.url;
                    a.download = img.name;
                    document.body.appendChild(a);
                    a.click();
                    document.body.removeChild(a);
                  }}
                >
                  <i className="fa fa-download"></i>{" "}
                  {/* Font Awesome "download" icon */}
                </button>
              </div>
            </div>
          </div>
        ))}
      </div>

      {/* Loader for infinite scroll */}
      {loading && (
        <div className="d-flex justify-content-center my-4">
          <div className="spinner-border" role="status">
            <span className="visually-hidden">Loading...</span>
          </div>
        </div>
      )}

      {/* This div triggers the infinite scroll when it comes into view */}
      <div ref={observer} style={{ height: "1px" }}></div>
    </div>
  );
};

export default Quotes;
