import React, { Suspense, useEffect, useState } from "react";
import { HelmetProvider } from "react-helmet-async";
import { useMediaQuery } from "react-responsive";
import { BrowserRouter as Router, Route, Routes } from "react-router-dom";
import HomeContent from "./Components/Constant/HomeContent";
import SeoMetaTags from "./Components/Constant/SeoMetaTags";
import ContentSection from "./Components/ContentSection";
import {
  GoogleAnalytics,
  initializeAnalytics,
} from "./Components/googleAnalytics/analytics";
import ProfileCard from "./Components/Lib/ProfileCard";
import "./Components/Styles/App.css";
import "./Components/Styles/AppOne.css";
import MainLoaderScreen from "./Components/Lib/LoaderScreen/MainScreenLoader";
import WhatsAppModal from "./Components/Lib/Modal/WhatsAppModal";
import Resources from "./Components/Resources";
import NotFound from "./Components/NotFound";
import OffersListPage from "./Components/Offers/OffersListPage";
import OfferDetailsPage from "./Components/Offers/OfferDetailsPge";
import Quotes from "./Components/Quotes";

const AppMain = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const {
    mobile,
    name,
    role,
    titleIcon,
    keywords,
    cardImage,
    siteUrl,
    twitterHandle,
    pageTitle,
    resourcePageTitle,
    resourceKeywords,
    resourceSiteUrl,
    siteBrowserUrl,
    siteResourceBrowserUrl,
    whatsAppChannelUrl,
  } = HomeContent;

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsLoading(false);
    }, 1);

    initializeAnalytics();

    // Check if the modal was shown before and if 1 hour has passed
    const checkModalShow = () => {
      const modalData = JSON.parse(localStorage.getItem("whatsappModal"));
      const currentTime = new Date().getTime();
      const oneHour = 60 * 60 * 1000;

      // Show modal if no previous data or 1 hour has passed
      if (!modalData || currentTime - modalData.timestamp > oneHour) {
        setShowModal(true);
        // Save the current time to localStorage
        localStorage.setItem(
          "whatsappModal",
          JSON.stringify({ showed: true, timestamp: currentTime })
        );
      }
    };

    const modalTimer = setTimeout(checkModalShow, 5000);

    return () => {
      clearTimeout(timer);
      clearTimeout(modalTimer);
    };
  }, []);

  const handleCloseModal = () => setShowModal(false);

  const isLargeScreen = useMediaQuery({ minWidth: 992 });

  return (
    <HelmetProvider>
      <SeoMetaTags
        name={name}
        role={role}
        titleIcon={titleIcon}
        keywords={[
          ...keywords,
          "ATS Resume Maker",
          "atsresumemaker.vallarasuk.com",
        ]}
        cardImage={cardImage}
        twitterHandle={twitterHandle}
        siteUrl={siteUrl}
        pageTitle={pageTitle}
      />

      <Router>
        <GoogleAnalytics />
        <div className="appOne-wrapper">
          <div className="container-fluid p-0">
            <div className="row p-0 m-0">
              <div className="col-lg-4 ps-0">
                {isLargeScreen && <ProfileCard />}
              </div>

              <div className="col-lg-8 px-lg-3 py-md-2 ">
                <div className="split-screen-container pt-md-4">
                  <Suspense>
                    {isLoading ? (
                      <MainLoaderScreen />
                    ) : (
                      <Routes>
                        <Route
                          path="/"
                          element={
                            <>
                              <SeoMetaTags
                                name={name}
                                role={role}
                                titleIcon={titleIcon}
                                keywords={[...keywords]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={siteBrowserUrl}
                                pageTitle={pageTitle}
                              />
                              <ContentSection />
                            </>
                          }
                        />

                        {/* <Route
                          path="/offers"
                          element={
                            <>
                              <SeoMetaTags
                                name="Special Offers | Vallarasu Kanthasamy"
                                role="Full Stack Developer"
                                titleIcon={cardImage}
                                keywords={[
                                  "course offers",
                                  "Udemy bundle deals",
                                ]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={`${siteUrl}/offers`}
                                pageTitle="Exclusive Course Offers"
                              />
                              <OffersListPage />
                            </>
                          }
                        /> */}

                        {/* <Route
                          path="/offers/:offerId"
                          element={
                            <>
                              <SeoMetaTags
                                name="Course Offer Details | Vallarasu Kanthasamy"
                                role="Full Stack Developer"
                                titleIcon={cardImage}
                                keywords={[
                                  "course details",
                                  "Udemy course bundle details",
                                ]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={`${siteUrl}/offers/:offerId`}
                                pageTitle="Course Offer Details"
                              />
                              <OfferDetailsPage />
                            </>
                          }
                        /> */}

                        <Route
                          path="/resources"
                          element={
                            <>
                              <SeoMetaTags
                                name={name}
                                role={role}
                                titleIcon={cardImage}
                                keywords={[
                                  ...resourceKeywords,
                                  "developer resources",
                                  "coding PDFs",
                                  "free developer PDFs",
                                  "programming tools",
                                  "ATS Resume Maker",
                                  "atsresumemaker.vallarasuk.com",
                                ]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={siteResourceBrowserUrl}
                                pageTitle={resourcePageTitle}
                                pageUrl={`${siteUrl}/resources`}
                              />
                              <Resources />
                            </>
                          }
                        />

                        <Route
                          path="/quotes"
                          element={
                            <>
                              <SeoMetaTags
                                name={name}
                                role={role}
                                titleIcon={cardImage}
                                keywords={[
                                  ...resourceKeywords,
                                  "developer resources",
                                  "coding PDFs",
                                  "free developer PDFs",
                                  "programming tools",
                                  "ATS Resume Maker",
                                  "atsresumemaker.vallarasuk.com",
                                ]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={siteResourceBrowserUrl}
                                pageTitle={resourcePageTitle}
                                pageUrl={`${siteUrl}/resources`}
                              />
                              <Quotes/>
                            </>
                          }
                        />

                        {/* Add more specific routes above if needed */}

                        <Route
                          path="*"
                          element={
                            <>
                              <SeoMetaTags
                                name="404 - Page Not Found | Vallarasu Kanthasamy"
                                role="Full Stack Developer"
                                titleIcon={cardImage}
                                keywords={["404", "Page Not Found", "Error"]}
                                cardImage={cardImage}
                                twitterHandle={twitterHandle}
                                siteUrl={`${siteUrl}/404`}
                                pageTitle="404 - Page Not Found"
                                pageUrl={`${siteUrl}/404`}
                                description="The page you are looking for does not exist. Explore other resources or visit the home page."
                              />
                              <NotFound />
                            </>
                          }
                        />
                      </Routes>
                    )}
                  </Suspense>
                </div>
              </div>
            </div>
          </div>
        </div>
        <WhatsAppModal
          show={showModal}
          handleClose={handleCloseModal}
          whatsAppChannelUrl={whatsAppChannelUrl}
        />
      </Router>
    </HelmetProvider>
  );
};

export default AppMain;
